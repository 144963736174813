import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../utilities/getToken";
import { UndoRounded } from "@mui/icons-material";
import SupportUtilityPaper from "./styled-components/SupportUtilityPaper";

export type ManualProductSyncProps = {
  message: ManualProductSyncRequest;
};

export type ManualProductSyncRequest = {
  tenantId: string | undefined;
  skus: string | undefined;
};

export default function ManualProductSync(props: ManualProductSyncProps) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(props.message);
  const baseURL =
    process.env.REACT_APP_API_URL +
    `v1/ManualProductSync/ManualProductSyncRequest`;
  const token = getToken();
  const [requestSending, setRequestSending] = useState(false);
  const [requestProcessed, setRequestProcessed] = useState(false);
  const [requestDialogText, setRequestDialogText] = useState("Processing...");

  useEffect(() => {
    if (
      !requestSending &&
      !requestProcessed &&
      (request.skus === undefined ||
        request.skus === "" ||
        request.tenantId === undefined ||
        request.tenantId === "")
    ) {
      setRequestDialogText(
        "Please provide a Tenant Id and no more than 100 SKUs before proceeding."
      );
    }

    if (
      !requestSending &&
      !requestProcessed &&
      request.skus !== undefined &&
      request.skus !== "" &&
      request.tenantId !== undefined &&
      request.tenantId !== ""
    ) {
      setRequestDialogText("Request is being processed...");
    }

    if (requestSending && !requestProcessed) {
      setRequestDialogText("Processing");
    }
  }, [requestSending, requestProcessed, request.skus, request.tenantId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFinished = () => {
    window.location.reload();
    setRequestProcessed(false);
  };

  const handleOk = () => {
    setRequestSending(true);

    axios
      .post(baseURL, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setRequestSending(false);
        if (response.status === 200) {
          setRequestProcessed(true);
          if (response.data.enqueueLimitExceeded) {
            setRequestDialogText(
              "Request of " + response.data.skuCount + " exceeded the limit."
            );
          } else if (
            response.data.skuCount > 0 &&
            !response.data.enqueueLimitExceeded
          ) {
            setRequestDialogText(
              response.data.skuCount +
                " product(s) have been queued to be resynced."
            );
          } else {
            setRequestDialogText(
              "No products found with those parameters. No resync initiated."
            );
          }
        } else {
          setRequestProcessed(true);
          setRequestDialogText(
            "Request failed with response status " +
              response.status +
              " and status text " +
              response.statusText
          );
        }
      });
  };

  const setTenantId = (value: string) => {
    setRequest({ ...request, tenantId: value });
  };

  const setSkus = (value: string) => {
    setRequest({ ...request, skus: value });
  };

  return (
    <SupportUtilityPaper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ margin: "10px" }}
          label="Tenant Id"
          value={request.tenantId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTenantId(event.target.value);
          }}
        ></TextField>
        <TextField
          style={{ margin: "3px" }}
          label="Skus"
          value={request.skus}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSkus(event.target.value);
          }}
        ></TextField>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="outlined" color="inherit" onClick={handleClickOpen}>
          Send Product Sync Request
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Request</DialogTitle>
        <DialogContent>{requestDialogText}</DialogContent>
        {!requestSending &&
          !requestProcessed &&
          (request.skus === undefined ||
            request.skus === "" ||
            request.tenantId === undefined ||
            request.tenantId === "") && (
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button autoFocus onClick={handleClose}>
                Ok
              </Button>
            </DialogActions>
          )}

        {!requestSending &&
          !requestProcessed &&
          request.skus !== undefined &&
          request.skus !== "" &&
          request.tenantId !== undefined &&
          request.tenantId !== "" && (
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button autoFocus onClick={handleOk}>
                Ok
              </Button>
            </DialogActions>
          )}

        {requestSending && !requestProcessed && (
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button autoFocus onClick={handleOk}>
              Ok
            </Button>
          </DialogActions>
        )}

        {requestProcessed && (
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={handleFinished}>Ok</Button>
          </DialogActions>
        )}
      </Dialog>
    </SupportUtilityPaper>
  );
}
