import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utilities/authConfig";

export const AuthHandler = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const account = accounts[0];
    if (!account) return;

    const request = {
      ...loginRequest,
      account,
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        localStorage.setItem("Token", response.accessToken);
      })
      .catch(async (e) => {
        console.warn("Silent token failed:", e);

        try {
          const response = await instance.acquireTokenPopup(request);
          localStorage.setItem("Token", response.accessToken);
        } catch (popupError) {
          console.error("Both silent and popup failed. Logging out and clearing storage.");

          // Clear localStorage
          localStorage.clear();

          // Force MSAL logout
          instance.logoutPopup({
            postLogoutRedirectUri: "/", // or login page
          });

          alert("Your session has expired. You have been logged out.");
        }
      });
  }, [accounts, instance]);

  return null;
};
