export const msalConfig = {
    auth: {
      clientId: `${process.env.REACT_APP_CLIENT_ID}`,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      knownAuthorities: ["login.microsoftonline.com"],
      redirectUri: `${process.env.REACT_APP_BASE_URL}`
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [`api://${process.env.REACT_APP_API_CLIENT_ID}/API.Support`]
  };