import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utilities/authConfig";
import { Button } from "@mui/material";

export const LogInButton = () => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setError(null);

    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      const account = loginResponse.account;
      console.log("Logged in as:", account?.username);

      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account!,
      });

      console.log("Access token:", tokenResponse.accessToken);
      localStorage.setItem("Token", tokenResponse.accessToken);
    } catch (e: any) {
      console.error("Login error:", e);
      setError(e.message || "Something went wrong during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleLogin} disabled={loading}>
        {loading ? "Logging in..." : "Log in"}
      </Button>
      {error && <div style={{ color: "red", marginTop: "0.5rem" }}>{error}</div>}
    </>
  );
};
